var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          "items-repo": _vm.scrubRepo,
          "is-data-from-model": false,
          fields: _vm.fields,
        },
        on: {
          "row-clicked": function ($event) {
            _vm.console.log(Object.keys($event))
            _vm.openDialog($event.lastScrubResult)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(visitValidated)",
            fn: function (data) {
              return [_c("div")]
            },
          },
          {
            key: "cell(lastScrubResult)",
            fn: function (data) {
              return [
                data.item
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "transparent" },
                            on: {
                              click: function ($event) {
                                return _vm.openDialog(data.item.lastScrubResult)
                              },
                            },
                          },
                          [
                            _c("b-icon-eye", {
                              attrs: { icon: "eye-fill", variant: "blue" },
                            }),
                          ],
                          1
                        ),
                        _c("b-icon", {
                          attrs: {
                            icon: "x-circle",
                            scale: "2",
                            variant: "danger",
                          },
                        }),
                        _vm._v(
                          "\n\n                " +
                            _vm._s(data.item.result) +
                            "\n\n            "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm.selectedScrubb.result
        ? _c(
            "b-modal",
            {
              attrs: {
                id: "modal-1",
                title: "Modal title",
                "ok-only": "",
                "ok-title": "OK",
                size: "xl",
                scrollable: "",
              },
              on: {
                ok: function ($event) {
                  _vm.selectedScrubb = {}
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c("scrubber", {
                    attrs: { "scrub-result": _vm.selectedScrubb.result },
                    model: {
                      value: _vm.selectedScrubb.visitValidated,
                      callback: function ($$v) {
                        _vm.$set(_vm.selectedScrubb, "visitValidated", $$v)
                      },
                      expression: "selectedScrubb.visitValidated",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }